/* @font-face {
    font-family: poppins;
    src: local(Poppins-Regular),
        url("../fonts/Poppins-Regular.ttf") format("opentype");
    font-display: swap;
} */

/* Animation CODE */

.circular-image-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  padding: 1rem 0 1rem 0;
}

.circular-image-wrapper .d-none {
  display: none;
}

.circular-image-wrapper .animated-circular-image {
  width: 30rem;
  max-width: 100%;
  flex-shrink: 0;
  overflow: visible;
}

.circular-image-wrapper .animated-circle-image * {
  overflow: visible;
}

.circular-image-wrapper .msg-wrapper {
  position: absolute;
  left: 50%;
  bottom: 2.5rem;
  width: calc(100% - 2rem);
  max-width: 37.5rem;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.56rem 2.8rem;
  background: linear-gradient(
    70deg,
    #00463e,
    #00463e 15%,
    #716000 40%,
    #0c3936 80%,
    #0c3936 100%
  );
}

.circular-image-wrapper .msg-wrapper.msg-wrapper-clone {
  opacity: 0;
  z-index: -1;
}

.circular-image-wrapper .img-wrapper {
  flex-shrink: 0;
  width: 4rem;
  aspect-ratio: 1;
  padding: 0.56rem;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fff;
  margin-right: 1.875rem;
}

.circular-image-wrapper .img-wrapper img {
  display: block;
  max-width: 100%;
  height: auto;
}

.circular-image-wrapper .msg-content {
  flex-grow: 1;
}

.circular-image-wrapper .title {
  margin: 0 0 0.125rem 0;
  color: #fff;
  font:
    700 1.5rem poppins,
    ui-sans-serif,
    system-ui;
}

.circular-image-wrapper .subtitle {
  margin: 0;
  color: #fff;
  font:
    1.1rem poppins,
    ui-sans-serif,
    system-ui;
}

@media screen and (max-width: 40rem) {
  .circular-image-wrapper .msg-wrapper {
    padding: 0.8rem 1.25rem;
  }

  .circular-image-wrapper .img-wrapper {
    margin-right: 1.2rem;
  }

  .circular-image-wrapper .title {
    font-size: 1.3rem;
  }

  .circular-image-wrapper .subtitle {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 31.25rem) {
  .circular-image-wrapper .msg-wrapper {
    bottom: 1rem;
  }
}
